import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StructuresState } from './structures.state';

const selectStructureState = createFeatureSelector<StructuresState>('structures');

export const selectData = createSelector(selectStructureState, (state: StructuresState) => state.data);
export const selectPages = createSelector(selectStructureState, (state: StructuresState) => state.pages);
export const selectStructureType = createSelector(selectStructureState, (state: StructuresState) => state.structureType);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({
	...data,
	pages,
}));
export const selectGridSearch = createSelector(selectStructureState, (state: StructuresState) => state.gridSearch);
export const selectStatus = createSelector(selectStructureState, (state: StructuresState) => state.status);
export const selectSort = createSelector(selectStructureState, (state: StructuresState) => state.sort);
export const selectStructureField = createSelector(selectStructureState, (state: StructuresState) => state.currentStructureField);
export const selectStructureFieldPath = createSelector(selectStructureState, (state: StructuresState) => state.paths);
export const selectCachedPages = createSelector(selectStructureState, (state: StructuresState) => state.cachedPages);
export const selectIsDragging = createSelector(selectStructureState, (state: StructuresState) => state.isDragging);
export const selectDraggingUUID = createSelector(selectStructureState, (state: StructuresState) => state.draggingUUID);
export const selectContainersCoordinates = createSelector(selectStructureState, (state: StructuresState) => state.containersCoordinates);
export const selectContainerUUIDToExpand = createSelector(selectStructureState, (state: StructuresState) => state.containerUUIDToExpand);
